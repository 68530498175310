<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Location List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0 ml-2">
            </va-button>
          </template>
        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Location"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deletelocation(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="getAllLocation()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Location Name</span>
            <va-input
              v-if="isCreate"
              v-model="name"
              placeholder="Enter Location Name"
              @input="searchLocation(name)"
              :error="!!LocationNameErrors.length"
              :error-messages="LocationNameErrors"
            />
            <div class="results" v-if="listOpen">
              <ul>
                <li v-for="name in LocationNameArr" @click="getInstanceLocation(name.name)">
                  <span>{{name.name}}</span>
                </li>
              </ul>
            </div>
            <va-input
              v-model.trim="name"
              type="text"
              v-if="isUpdate"
              :error="!!LocationNameErrors.length"
              :error-messages="LocationNameErrors"
            />
            <span class="va-form-label va-form-required-label">Description</span>
            <va-input
              v-model.trim="description"
              type="text"
              placeholder="Enter Description"
              :error="!!DescriptionErrors.length"
              :error-messages="DescriptionErrors"
            />
            <span class="va-form-label va-form-required-label">Duration</span>
            <va-input
              v-model.trim="duration"
              type="text"
              placeholder="e.g: 0-9 km"
              :error="!!DurationErrors.length"
              :error-messages="DurationErrors"
            />
            <div>
              <span v-if="isCreate" class="va-form-label va-form-required-label">Image (514 X 394)</span>
              <span v-if="isUpdate" class="va-form-label">Image (500 X 394)</span>
              <va-file-upload
                class="mt-2 ml-1"
                @input="CheckFile('horizontal')"
                v-model="image_file"
                type="gallery"
              />
              <label class="ml-1" v-if="ImageUrlShown">{{existing_image_name}}</label>
              <div class="ml-1" v-if="ImageUrlShown"><img :src=image_url height="250px" width="250px" /></div>
            </div>
            <div class="mt-2">
              <span class="va-form-label">Upload Video</span>
              <div>
                <input
                  type="file"
                  style="display: none;"
                  ref="location_video"
                  accept=".mp4"
                  id="location_video"
                  class="mt-2"
                  v-on:change="validateVideo($event)"
                />
              </div>
              <va-button
                type="submit"
                class="mt-2 ml-1"
                @click.prevent="$refs.location_video.click()">
                Upload Video
              </va-button>
              <label class="ml-4">{{ video_dis_name }}</label>
            </div>
            <video
              class="ml-2"
              v-if="!oldUpload"
              :src="instant_video"
              width="520"
              height="440"
              style="display: none; margin-top: -10px;"
              controls
              autoplay
            />
            <video
              lass="ml-2"
              v-if="oldUpload"
              id="myVideo"
              width="520"
              height="440"
              style="margin-top: -10px;"
              autoplay
              controls
              :src=videoUrl
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="getAllLocation()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="locationProcess('create')">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="locationProcess('update')">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'Location',
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isCreate: false,
      isUpdate: false,
      isshowgrid: true,
      isshowForm: false,
      showRemoveModal: false,
      msg: '',
      name: '',
      LocationNameErrors: [],
      description: '',
      DescriptionErrors: [],
      duration: '',
      DurationErrors: [],
      location_list: [],
      image_file: [],
      ImageUrlShown: false,
      location_video: [],
      video_dis_name: '',
      existing_image_name: '',
      oldUpload: false,
      videoUrl: '',
      instant_video: '',
      location_id: '',
      listOpen: false,
      LocationNameArr: [],
    }
  },
  computed: {
    formReady () {
      return !this.LocationNameErrors.length && !this.DescriptionErrors.length && !this.DurationErrors.length
    },
    fields () {
      return [
        {
          name: 'name',
          title: 'Location Name',
        },
        {
          name: 'duration',
          title: 'Duration',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
      ]
    },
    filteredData () {
      return search(this.term, this.location_list)
    },
  },
  created () {
    this.getAllLocation()
  },
  methods: {
    CheckFile (type) {
      if (this.image_file[0]) {
        this.ImageUrlShown = false
        var image_file = this.image_file[0]
        var file_name = image_file.name.split('.').pop()
        if (file_name.toLowerCase() === 'png' || file_name.toLowerCase() === 'jpg' || file_name.toLowerCase() === 'jpeg') {
          const img = new Image()
          const width = '514'
          const height = '394'
          const reader = new FileReader()
          reader.readAsDataURL(image_file)
          reader.onload = evt => {
            img.onload = () => {
              if (img.width == width && img.height == height) {
                this.image_file[0] = image_file
              } else {
                this.image_file = ''
                return Vue.notify({ text: 'Please check the image size', type: 'error' })
              }
            }
            img.src = evt.target.result
          }
        } else {
          this.image_file = ''
          return Vue.notify({ text: 'Please check the image Format', type: 'error' })
        }
      }
    },
    validateVideo (eve) {
      var video = eve.target.files[0]
      var size = eve.target.size
      if ((size / (1024 * 1024)) <= 200) {
        this.video_dis_name = video.name
        this.location_video = video
        const blobURL = URL.createObjectURL(video)
        this.instant_video = blobURL
        document.querySelector('video').style.display = 'block'
        document.querySelector('video').src = blobURL
        return true
      } else {
        alert('please upload video less than 200 MB')
        this.video_dis_name = ''
        return false
      };
    },
    getAllLocation () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/location').then(response => {
        loader.hide()
        this.location_list = response.body
        this.isshowgrid = true
        this.isshowForm = false
        this.oldUpload = false
      }, err => {
        loader.hide()
        if (err && err.body) Vue.notify({ text: err.body, type: 'error' })
      })
    },
    locationProcess (type) {
      this.LocationNameErrors = this.name ? [] : ['Location Name is required']
      this.DescriptionErrors = this.description ? [] : ['Description is required']
      this.DurationErrors = this.duration ? [] : ['Duration is required']

      if (!this.formReady) {
        return Vue.notify({ text: 'Fill the form correctly', type: 'error' })
      };
      if (!this.image_file[0] && !this.ImageUrlShown && type == 'create') {
        return Vue.notify({ text: 'Please Upload the Image', type: 'error' })
      };

      var payload = new FormData()
      payload.append('name', this.name)
      payload.append('description', this.description)
      payload.append('duration', this.duration)
      if (!this.ImageUrlShown && this.image_file.length > 0) {
        if (this.image_file.length != 0) payload.append('image', this.image_file[0])
      } else {
        payload.append('image', this.image_url)
      }
      if (!this.oldUpload && this.location_video) {
        payload.append('video', this.location_video)
      } else {
        payload.append('video', this.videoUrl)
      }

      if (type == 'create') {
        var arrName = []
        this.location_list.map(function (data) { arrName.push(data.name) })
        if (arrName.includes(this.name)) {
          return Vue.notify({ text: 'The given location name already exists', type: 'error' })
        };
        var requestUrl = this.$http.post(config.menu.host + '/location', payload)
      } else {
        var arrName = []; var vm = this
        this.location_list.map(function (data) {
          if (vm.backup_location != data.name) {
            arrName.push(data.name)
          };
        })
        if (arrName.includes(this.name)) {
          return Vue.notify({ text: 'The given location name already exists', type: 'error' })
        };
        var requestUrl = this.$http.put(config.menu.host + '/location/' + this.location_id, payload)
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      requestUrl.then(responseData => {
        loader.hide()
        Vue.notify({ text: responseData.body, type: 'success' })
        this.getAllLocation()
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
          this.isshowForm = true;
        };
      })
    },
    add () {
      this.title = 'Create Location'
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = true
      this.isUpdate = false
      this.errorClear()
      this.name = ''
      this.description = ''
      this.duration = ''
      this.video_dis_name = ''
      this.existing_image_name = ''
    },
    errorClear () {
      this.LocationNameErrors = []
      this.DescriptionErrors = []
      this.DurationErrors = []
      this.oldUpload = false
      this.videoUrl = ''
      this.ImageUrlShown = false
      this.image_file = []
      this.location_video = []
      this.instant_video = ''
    },
    edit (row) {
      this.title = 'Update Location'
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
      this.errorClear()
      this.name = row.name
      this.backup_location = row.name
      this.location_id = row.location_id
      this.description = row.description
      this.duration = row.duration
      this.ImageUrlShown = !!(row.image)
      this.existing_image_name = (row.image) ? row.image.split('/').pop() : ''
      if (this.ImageUrlShown) this.image_url = row.image
      if (row.video) {
        this.oldUpload = true
        this.videoUrl = row.video
        this.video_dis_name = row.video.split('/').pop()
      };
    },
    remove (row) {
      this.msg = 'Are you sure to delete the Location ' + row.name + ' ?'
      this.entity = row
      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    deletelocation (data) {
      this.$http.delete(config.menu.host + '/location/' + data.location_id).then(resp => {
        Vue.notify({ text: resp.body, type: 'success' })
        this.getAllLocation()
      }, err => {
        if (err && err.body) Vue.notify({ text: err.body, type: 'error' })
      })
    },
    search: function (term) {
      this.term = term
    },
    searchLocation (name) {
      if (name.length > 0) {
        var payload = { location_term: this.name }
        this.$http.post(config.menu.host + '/location/search', payload).then(response => {
          this.listOpen = true
          this.LocationNameArr = response.body
        })
      } else {
        this.listOpen = false
      };
    },
    getInstanceLocation (name) {
      this.listOpen = false
      if (name) {
        var filter = this.LocationNameArr.filter(function (instant) {
          return instant.name == name
        })
        if (filter.length > 0) {
          this.name = filter[0].name
          this.description = filter[0].description
          this.duration = filter[0].duration
          this.existing_image_name = filter[0].image.split('/').pop()
          this.image_url = filter[0].image
          this.ImageUrlShown = !!(filter[0].image)
          this.oldUpload = true
          this.videoUrl = filter[0].video
          this.video_dis_name = filter[0].video.split('/').pop()
        };
      };
    },
  },
}
</script>

<style>
.results ul {
  width: 50%;
  padding: 6px;
}

.results li {
  background-color: rgba(221, 221, 221, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
}

.searchbox {
  text-align: left;
}

.searchbox input {
  height: 30px;
  font-size: 16px;
  padding: 0 10px;
}

.results li:hover {
  background-color: rgba(200, 194, 197, 0.8);
  cursor: pointer;
}

.results li span {
  display: inline-block;
}
</style>
